import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/theme/layout'
import Seo from '../../components/seo'

import './legal.scss'

export default function PrivacyPolicy() {
    return (
        <Layout>
            <Seo title='Privacy policy' />
            <div className='legal-container'>
                <h1>Privacy Policy</h1>

                <p>Welcome! This Privacy Policy (this <strong>“Policy”</strong>) explains how we at Qiwio (<strong>“Qiwio”, “we”, “us”, “our”</strong>), collect, use, disclose and otherwise process personal information. We understand
                that you are aware of and care about your own personal privacy interests, and we take that seriously. This Policy describes the personal information we may collect from you, the purposes for which we collect it, how
                we use it and how we keep it secure. This Policy also describes the choices you can make about your personal information, including how you can manage, delete or have access to your personal information. Qiwio will
                not collect, use or disclose your personal information other than in compliance with this Policy.</p>

                <h2>Data Protection Officer</h2>
                <p>Qiwio is headquartered in Halmstad, Halland, Sweden. Qiwio has appointed an internal data protection officer for you to contact if you have any questions or concerns about Qiwio’s personal data policies or practices.
                Qiwio’s data protection officer’s name and contact information are as follows:</p>
                <p>Amin El-Bekry <br /> Qiwio AB <br /> Kristinebergsvägen 22 <br /> 302 41 Halmstad <br /> Sweden <br /> amin@qiwio.io</p>

                <h2>What is personal information?</h2>
                <p>Personal information is information that is about you as an identifiable individual. Aggregated, statistical or de-identified information is not personal information. Information generated through your use of our
                websites or watching videos will not be personal information unless we can combine it with other information that would identify you.</p>

                <h2>When does this privacy policy apply?</h2>
                <p>Qiwio is a video platform service that helps businesses transform communications and sales through the use of online video. This Privacy Policy explains how we collect, use and disclose your personal information
                in the course of our business. This Privacy Policy also covers the information we may collect offline, such as when you telephone us, visit us in person or interact with us at an event, for example enter a contest
                or speak to us at a trade show. When our customers collect your personal information through our video platform service in the course of their business, their use of your personal information is governed by their
                privacy policy, not ours.</p>

                <p>This Privacy Policy applies to the personal information we collect and use when you:</p>
                <ul>
                    <li>Visit and interact with the Qiwio Websites.</li>
                    <li>Interact with the Qiwio Services.</li>
                    <li>Communicate and interact with us on the telephone, in person or through other means.</li>
                </ul>

                <p>This Privacy Policy does not apply to the practices of third parties that use the Qiwio Services to distribute or show you videos or other content using our Services. You should review the privacy policies of
                those third parties for information on how they use your personal information. Please contact our <Link to='mailto: amin@qiwio.io'>Data Protection Officer</Link> if you have any questions in this regard or require
                assistance in contacting a third party.</p>

                <p>Also, the Qiwio Websites and Qiwio Services may contain links to third party websites or services. These links are intended for your convenience only. These websites and services are wholly independent from
                Qiwio and may have separate privacy policies and data collection practices, independent of Qiwio. We have no responsibility or liability for these websites, their policies or their privacy practices. We encourage
                you to read the privacy policy of any websites you visit.</p>

                <h2>What are the Qiwio Websites and Services?</h2>
                <p>The Qiwio Websites include our website <Link to='/'>www.qiwio.io</Link>, any subdomains, and any other Qiwio websites. Our Services include downloadable computer software and any products or services purchased or otherwise
                made available from Qiwio Websites.</p>
                <p>Qiwio’s Services include video content libraries and distribution platforms that third parties can use to show viewers their videos.</p>

                <h2>When and how do we collect information directly from you?</h2>
                <p>In most cases, we collect information about you when you give it directly to us. For example, we you create an account or purchase our services. We also collect information when you call us, visit our offices or interact with
                us at an event. Also, like many websites and technologies, we automatically collect technical data when you visit and interact with Qiwio Websites and Qiwio Services.</p>
                <p>Qiwio collects personal information that you give to us when you:</p>
                <ul>
                    <li>Use the Qiwio Websites to sign up for newsletters, download a report or software, enter a contest, participate in a survey.</li>
                    <li>Communicate with us through email, chat, or social media, or by phone or any other means.</li>
                    <li>Register for an account to use the Qiwio Services.</li>
                    <li>Provide us with your name, email address or other identifying information before or after viewing a video provided through the Qiwio Websites or the Qiwio Services.</li>
                    <li>Visit us in person and check into reception.</li>
                    <li>Enter a contest at an event.</li>
                </ul>

                <p>We may also collect:</p>
                <ul>
                    <li>Technical information about your use of the Qiwio Websites and the Qiwio Services.</li>
                    <li>Information from our sales and advertising partners.</li>
                    <li>Publicly available information in online directories or social media, for example your business contact information may be enriched with data from LinkedIn.</li>
                </ul>

                <p>We may combine your personal information with other information we receive.</p>

                <h2>What type of information do we collect?</h2>
                <p>We collect a variety of data including your contact information, payment details, business information, user uploaded content, and communications you have with our team. The precise types of information that is collected
                will depend on your interaction with Qiwio, Qiwio Websites or the Qiwio Services.</p>
                <p>Here are some examples of the personal information we collect:</p>
                <ul>
                    <li>Contact information, including name, mailing address, telephone number, and email address.</li>
                    <li>Financial information, including credit card number, security code, billing address, authentication and security information and other billing data.</li>
                    <li>Business information, including business name, title, employee information, business contact information and location.</li>
                    <li>Images and videos, such as the images you upload to your Account and video content you upload through our Services.</li>
                    <li>Job application information, including name, address, employment history, education background, interests and other information included in cover letters, resumes and job applications.</li>
                    <li>Visitor information, including name, organization information and photo for identification purposes.</li>
                    <li>Communication information, such as the content of your inquiries, complaints and feedback directed to our team.</li>
                </ul>

                <p>We may also collect:</p>
                <ul>
                    <li>Technical information about your use of the Qiwio Websites and the Qiwio Services.</li>
                    <li>Information from our sales and advertising partners.</li>
                    <li>Publicly available information in online directories or social media, for example your business contact information may be enriched with data from LinkedIn.</li>
                </ul>
                <p>We may combine your personal information with other information we receive.</p>

                <h2>Does Qiwio use cookies and other technologies to collect technical data?</h2>
                <p>When you visit the Qiwio Websites, we receive data from your devices, including your IP address. This technical data generally will not identify you as a visitor. However, in some instances, this technical
                data may be linked to you if you have given us identifying information about you.</p>
                <p>Like many websites, Qiwio uses cookies, etags, web beacons and other similar technologies to automatically collect certain technical data from your computer or mobile device. The data we collect includes your IP address,
                browser type, how you interact with the Qiwio Websites and more. We use this data for several reasons, such as to customize your experience or to assist Qiwio and our partners with targeted advertising.</p>
                <p>While some visitors to the Qiwio Websites may not be identified by the technical data that is collected, others may be, for example if you have an Account, the technical data will be linked to you.</p>
                <p>We encourage you to visit and read our separate <Link to='/legal/cookies'>Cookies and Advertising Policy</Link> to learn more, including what choices you have with respect to the use of cookies, tracking and targeting
                advertising.</p>

                <h2>What are business analytics and aggregated data?</h2>
                <p>Business analytics involves the analysis of data to understand trends and insights about how individuals use the Qiwio Websites and the Qiwio Services. Aggregated data means data that is combined so that you are no
                longer identifiable. We use analytics and aggregated data to improve our business.</p>
                <p>Qiwio uses business analytics to help us to better understand the functionality of the Qiwio Websites and the Qiwio Services. The information gathered includes how often you use our Services, which features you use
                or don’t use, aggregated usage and performance data.</p>
                <p>Qiwio also anonymizes personal information and technical data in a manner that can no longer identify you individually. We use aggregate data to help us understand how customers, viewers and other individuals use and
                interact with the Qiwio Websites and Services, to improve our products, services and brands, generate reports and market Qiwio.</p>
                <p>We may also provide the insights we learn from business analytics and aggregated information to our partners and customers, who may use such information to understand how often and in what ways people use the Qiwio
                Websites and the Qiwio Services.</p>

                <h2>For what purpose do we collect your information?</h2>
                <p>We use your personal information to communicate with you, to provide you with assistance, products and services that you request, and to adapt the Qiwio Websites and the Qiwio Services to your interests. We also use your
                personal information to understand how you use the Qiwio Websites and the Qiwio Services to protect and improve our business.</p>
                <p>Here are examples of the purposes for which we use your personal information (and the associated lawful basis for doing so):</p>
                <p><strong>Your consent:</strong></p>
                <ul>
                    <li>Reviewing and processing job applications.</li>
                    <li>Personalizing your experience with the Qiwio Websites.</li>
                    <li>Marketing our products, services and brand, including administering contests and public events.</li>
                </ul>
                <p><strong>For the performance of a contract or agreement:</strong></p>
                <ul>
                    <li>Processing your purchases, including keeping track of transactions and reporting back to you.</li>
                    <li>Providing information or services requested by you.</li>
                    <li>Permitting you to send invitations friends to use our Services.</li>
                    <li>Assisting in optimizing the delivery of your content to viewers.</li>
                    <li>E-mail gating of Content.</li>
                    <li>Delivery of Content and information to third party services and partners.</li>
                </ul>
                <p><strong>Our legitimate interests, which we have balanced with the interests of our clients, suppliers and business contacts:</strong></p>
                <ul>
                    <li>Communicating with you in accordance with applicable law.</li>
                    <li>Administering and managing the Qiwio Websites and our business operations.</li>
                    <li>Protecting against fraud or error.</li>
                    <li>Evaluating statistics on Qiwio Websites activity.</li>
                    <li>Performing statistical analyses of your behaviour and characteristics, in order to measure interest in and use of the various sections of the Qiwio Websites.</li>
                    <li>Assisting in diagnosing problems with Qiwio servers.</li>
                    <li>Fulfilling any other purpose that would be reasonably apparent to the average person at the time that we collect it.</li>
                </ul>
                <p><strong>Compliance with a legal obligation to which we are subject:</strong></p>
                <ul>
                    <li>Complying with legal and governmental requirements.</li>
                </ul>
                <p>From time to time, we collect personal information for other purposes. In that case, we will identify any additional purposes for which we will collect your personal information, before or at the time of collection, and we
                will make sure to collect, use or disclose your information for such additional purpose(s) as long as it is in accordance with applicable regulations.</p>

                <h2>Tell me about Qiwio and social media</h2>
                <p>You may choose to integrate your Qiwio account with various social media platforms such as Facebook, Twitter, LinkedIn, and Google+. This functionality is optional and you can link and unlink your account at any time.
                If you do decide to integrate, Qiwio may collect some of the following personal information from your social media profile:</p>
                <ul>
                    <li>The name associated with your account.</li>
                    <li>The ID/username associated with your account.</li>
                    <li>For YouTube, the day by day view count from videos on your YouTube channel that are associated with videos on your Qiwio account.</li>
                </ul>

                <h2>Tell me about uploaded content and third-party information</h2>
                <p>When you post audio-visual materials to your website or to a third party website, we collect information about your account and the use of the content that you post. If you use our invitation features, such as chats and
                sending invitations to friends, Qiwio may collect some information from them.</p>
                <p>Integrating Qiwio Services with third party websites (such as Salesforce, HubSpot and Marketo) is optional and you can unlink your account at any time.</p>
                <p>If you use the Qiwio Services and you post audio visual materials including, without limitation, videos, links, logos, artwork, graphics, pictures, advertisements, sound and other related intellectual property contained
                in such materials to your website or to a third party website, Qiwio tracks and captures information associated with your account and the use of the content by those, such as viewers, that access your content.</p>
                <p>Some Qiwio Services will provide you the opportunity to communicate with others, such as by sending an invitation to a friend. If you choose to take advantage of this functionality, Qiwio Services may require you to
                provide Qiwio with certain information about the person with whom you wish to communicate (e.g., name, e-mail address, etc.). When using this functionality, you must have the individual’s permission to provide Qiwio with
                their information. Qiwio uses the information for the purposes of facilitating the requested communication. Please be aware that when you use any invitation functionality, your e-mail address, name or username, and message
                may also be included in the communication sent to your addressee(s).</p>

                <h2>Tell me about advertising practices</h2>
                <p>We use technologies and/or belong to marketing programs in order to provide visitors, users and customers with advertising targeted to their interests. To learn more about interest-based advertising and what choices you
                have, please visit our dedicated <Link to='/legal/cookies'>Cookies and Advertising Policy</Link>.</p>

                <h2>When does Qiwio share or disclose personal information?</h2>
                <p>Sometimes, Qiwio shares or discloses your personal information and technical data to outside parties, such as parties that you choose to share your personal information with, our partners and service providers.
                For example, Qiwio shares information with our service providers who assist Qiwio with marketing, data hosting and credit card payments. Qiwio may disclose personal information due to legal requirements or enforcement,
                such as defending against legal claims or to investigate and prevent illegal activities. We may also disclose viewer data to our customers, such as how many times a video was watched and location of the viewers.</p>
                <p>Qiwio may share and disclose information and technical data in the following circumstances:</p>
                <ul>
                    <li><strong>Trusted third party service providers.</strong> Qiwio uses third parties to assist us in administering and providing the Qiwio Websites and Qiwio Services or to provide other services on our behalf.
                    Examples include managing content, analyzing data, providing marketing assistance, integrations of third party services such as CRM and MAP services, processing credit card payments and providing customer service.</li>
                    <li><strong>Legal requests.</strong> Qiwio may disclose personal information when required by law to respond to subpoenas, court orders or other legal process by authorities with jurisdiction.</li>
                    <li><strong>Enforcement.</strong> Qiwio may disclose personal information in order to establish or exercise our legal rights, or to defend against legal claims, or when we believe it is necessary to share information
                    in order to investigate, prevent, or take action regarding illegal activities, data breaches, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our Terms of Use,
                    or as otherwise required by law.</li>
                    <li><strong>Business transaction.</strong> In the unlikely event that we go out of business, enter bankruptcy or if we are acquired as a result of a transaction such as a merger, acquisition or asset sale, your personal
                    information may be disclosed or transferred to the third-party acquirer in connection with the transaction.</li>
                    <li><strong>Customer content.</strong> Some of our Qiwio Services enable you, as customers, to upload, manage and publish content to a customer’s own website or to third party websites. Any information that you disclose
                    through the uploaded content may become publicly available. As we are not responsible for any personal information you disclose through Qiwio Services, we recommend that you exercise caution when deciding to disclose
                    personal information through this functionality.</li>
                </ul>
                <p>If you are from the European Union, Qiwio will only transfer your personal information if the appropriate safeguards required by the GDPR are in place.</p>

                <h2>Information collected by our customers using Qiwio services</h2>
                <p>We may provide customers with certain identifiable usage information directly related to the videos and other content that they make available through the Qiwio Services. This includes information about who specifically
                viewed the content and how many times a particular content was watched by a particular viewer. Viewers should be aware that customers may share such usage information with other third parties (see the next section).
                We are not responsible for the use of any information by such customers and their third party services. With that said, Qiwio requires all customer to use the Qiwio Services in line with applicable laws. Please contact our
                <Link to='mailto: amin@qiwio.io'>Data Protection Officer</Link> if you have any questions in this regard or require assistance in contacting a one of our customers.</p>
                <p>Here are examples of the personal information our customer may attempt to collect with the Qiwio Services:</p>
                <ul>
                    <li>Standard contact information such as name, title, email address, physical address, phone number, etc.</li>
                    <li>Information about an individual’s computer or mobile device or technology usage, including (for example) IP address, MAC address, unique device identifiers, unique identifies set in cookies, and any information passively
                    captured about a person’s online activities, browsing, application or hotspot usage or device location.</li>
                </ul>
                <p>The Qiwio Services are not intended to be used to collect special categories of personal information such as racial or ethnic origin, religious or philosophical beliefs, genetic data, or sexual orientation etc. In the rare
                case that our customer may attempt to collect special categories of personal information using the Qiwio Services, it will be be provided by you knowingly (for example: by you choosing to disclose it.)</p>

                <h2>Use of Third Party Integrations by our Customers</h2>
                <p>Our customers may integrate the Qiwio Services with other third party applications and platforms. In doing so, our customers may transfer your personal information from our platform to the third party. Qiwio is not responsible
                for the transfer of such information by our customers. Please contact our <Link to='mailto: amin@qiwio.io'>Data Protection Officer</Link> if you have any questions in this regard or require assistance in contacting a one of our
                customers.</p>
                <p>Integrations offered by Qiwio include, but are not limited to, the following:</p>
                <ul>
                    <li>Facebook (<a href='https://www.facebook.com/policy.php' target='_blank' rel="noreferrer">https://www.facebook.com/policy.php</a>)</li>
                    <li>Gmail (<a href='https://policies.google.com/privacy' target='_blank' rel="noreferrer">https://policies.google.com/privacy</a>)</li>
                    <li>Microsoft Outlook (<a href='https://privacy.microsoft.com/en-ca/privacystatement' target='_blank' rel="noreferrer">https://privacy.microsoft.com/en-ca/privacystatement</a>)</li>
                    <li>YouTube (<a href='https://policies.google.com/privacy' target='_blank' rel="noreferrer">https://policies.google.com/privacy</a>)*</li>
                </ul>
                <p>* Note: You can limit or revoke access to your YouTube data via the Google security settings page
                at <a href="https://security.google.com/settings/security/permissions" target='_blank' rel="noreferrer">https://security.google.com/settings/security/permissions</a></p>

                <h2>How does Qiwio keep personal information safe?</h2>
                <p>We employ reasonably appropriate security measures to protect personal information against loss, unauthorized use, access, alteration, modification, disclosure or destruction. These measures include physical,
                organizational and technological measures. If we use third parties to help us provide our Services, we require such service providers to use similar safeguards to those we use. We also require service providers
                to limit access to your personal information and to keep it strictly confidential. Service providers are only permitted to use your personal information for the sole purpose of carrying out services on our behalf.</p>
                <p>As businesses like Qiwio move towards greater use of technology, there is also an increasing drive to keep information secure and confident.</p>
                <p>However, no data transmission over the internet can be guaranteed to be 100% secure. As a result, while we are committed to protecting your personal information, we cannot ensure or warrant the security of any
                information you provide to us.</p>

                <h2>Where is personal information stored?</h2>
                <p>Qiwio has its headquarters in Sweden and the Qiwio Services are hosted in Sweden. Some or all of the personal information we collect may be stored or processed on servers located Sweden.</p>
                <p>Information may also be stored outside the country you reside, whose privacy and data protection laws may differ from your jurisdiction. As a result, this information may be subject to access requests from
                governments, courts, or law enforcement in those jurisdictions according to laws in those jurisdictions.</p>
                <p>Qiwio endeavors to apply suitable safeguards to protect the privacy and security of your personal data and to use it only consistent with your relationship with Qiwio and the practices described in this
                Privacy Notice. Qiwio also minimizes the risk to your rights and freedoms by not collecting or storing sensitive information about you.</p>

                <h2>How long will personal information be retained?</h2>
                <p>Qiwio retains personal information only as long as necessary to serve the identified purposes for which it was collected, subject to legal requirements.</p>

                <h2>A note about children</h2>
                <p>Protecting the safety of children when they use the Internet is very important to us. The Qiwio Websites and Qiwio Services are not designed or intended for use by children or anyone under the age of 13.
                Qiwio does not knowingly collect the information from children. Please contact us at <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link> for any concerns or questions about children’s personal information.</p>

                <h2>Your choices</h2>
                <p>You have options when it comes to your personal information.</p>
                <p>Opt-out of email communications. If you have subscribed to receive periodic promotional and marketing emails from Qiwio and would like to now opt-out, you can do so by any of the following options:</p>
                <ul>
                    <li>Clicking ‘unsubscribe’ in the email.</li>
                    <li>Send an e-mail to <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link></li>
                </ul>
                <p>If you do opt-out of receiving promotional and marketing messages, Qiwio may still contact you regarding our business relationship with you.</p>
                <p><strong>Access, verify, correct, update or delete personal information.</strong> You can update certain information by logging into your account. You may also submit your request
                to <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link>. Qiwio shall respond and execute your request, subject to any contractual or legal restrictions.</p>
                <p><strong>Close your Qiwio account.</strong> If you wish to delete your account and stop doing business with Qiwio, please contact our customer service representative
                at <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link>.</p>
                <p><strong>Information processed by Qiwio on behalf of one of our Customers.</strong> Please contact our <Link to='mailto: amin@qiwio.io'>Data Protection Officer</Link> if you require assistance in contacting
                one of our customers in order to access, verify, correct, update or delete personal information that may have been collected by Qiwio Services on their behalf.</p>
                <p><strong>Opt-out of cookies, tracking technologies and / or targeted advertising.</strong> Please visit our dedicated <Link to='/legal/cookies'>Cookies and Advertising Policy</Link> to learn what choices you have.</p>
                <p><strong>Complaints.</strong> We would be happy to first discuss and attempt to resolve your issue. Please contact us immediately at <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link>. If we cannot resolve it,
                we will provide you with the contact information of the appropriate privacy authority.</p>
                <p>See the Contact Us link below to learn how to reach us for other inquiries.</p>

                <h2>Contact us</h2>
                <p>Questions or complaints about this Privacy Policy or Qiwio’s privacy practices? You may contact us directly as follows:</p>
                <p><strong>Email:</strong> <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link></p>
                <p><strong>Mail:</strong> <br /> Qiwio AB, <strong>Attention: Privacy Officer</strong> <br /> Kristinebergsvägen 22 <br /> 302 41 Halmstad <br /> Sweden</p>

                <h2>Changes to this Privacy Policy</h2>
                <p>We may change this Privacy Policy. Please check back periodically for changes. If we make any significant changes, we will endeavour to communicate this to you where possible. Your continued use and interaction
                following any changes to the Privacy Policy constitutes acceptance of those changes.</p>
                <p><strong>Last Updated: 24 May 2022</strong></p>
            </div>
        </Layout>
    )
}